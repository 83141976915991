import { type ColumnId } from './types'
import { type ReportKpiType, type ReportKpiRow, getName } from '@terros/common'
import { DateTime } from 'luxon'

export type ColumnDef = {
  id: ColumnId
  label: string
  description: string
  width: number
  visibleByDefault: boolean
  pinned: boolean
  requiredKpis: ReportKpiType[]
  canFilter: boolean
}

export const ALL_COLUMNS: ColumnDef[] = [
  {
    id: 'avatar',
    label: 'Photo',
    description: 'The profile photo',
    width: 35,
    visibleByDefault: true,
    pinned: true,
    requiredKpis: [],
    canFilter: false,
  },
  {
    id: 'name',
    label: 'Name',
    description: 'The name of the user or team',
    width: -1,
    visibleByDefault: true,
    pinned: true,
    requiredKpis: [],
    canFilter: false,
  },
  {
    id: 'contactCount',
    label: 'Contact',
    description: 'The number of knocks',
    width: 60,
    visibleByDefault: false,
    pinned: false,
    requiredKpis: ['contact.count'],
    canFilter: true,
  },
  {
    id: 'pitchCount',
    label: 'Pitch',
    description: 'The number of pitches',
    width: 60,
    visibleByDefault: true,
    pinned: false,
    requiredKpis: ['contact.pitches'],
    canFilter: true,
  },
  {
    id: 'setterLeadCount',
    label: 'Str Leads',
    description: 'The number of leads',
    width: 55,
    visibleByDefault: true,
    pinned: false,
    requiredKpis: ['setter.lead.count'],
    canFilter: true,
  },
  {
    id: 'setterSetCount',
    label: 'Str Sets',
    description: 'The number of appointments set by setter',
    width: 60,
    visibleByDefault: false,
    pinned: false,
    requiredKpis: ['setter.set.count'],
    canFilter: true,
  },
  {
    id: 'setterSitCount',
    label: 'Str Sits',
    description: 'The number of sits by setter',
    width: 60,
    visibleByDefault: false,
    pinned: false,
    requiredKpis: ['setter.sat.count'],
    canFilter: true,
  },
  {
    id: 'setterSetToSitRatio',
    label: 'Str S/S',
    description: 'The ratio of sets to sits',
    width: 60,
    visibleByDefault: false,
    pinned: false,
    requiredKpis: ['setter.set.count', 'setter.sat.count'],
    canFilter: true,
  },
  {
    id: 'setterClosedCount',
    label: 'Str Closes',
    description: 'The number of closes by setter',
    width: 60,
    visibleByDefault: false,
    pinned: false,
    requiredKpis: ['setter.closed.count'],
    canFilter: true,
  },
  {
    id: 'setterApprovedCount',
    label: 'Str Approved',
    description: 'The number of approved accounts by setter',
    width: 60,
    visibleByDefault: false,
    pinned: false,
    requiredKpis: ['setter.approved.count'],
    canFilter: true,
  },
  {
    id: 'setterInstalledCount',
    label: 'Str Installed',
    description: 'The number of installed accounts by setter',
    width: 60,
    visibleByDefault: false,
    pinned: false,
    requiredKpis: ['setter.installed.count'],
    canFilter: true,
  },
  {
    id: 'setterArchivedCount',
    label: 'Str Archived',
    description: 'The number of archived events',
    width: 60,
    visibleByDefault: false,
    pinned: false,
    requiredKpis: ['setter.archived.count'],
    canFilter: true,
  },
  {
    id: 'closerLeadCount',
    label: 'Clsr Leads',
    description: 'The number of leads',
    width: 55,
    visibleByDefault: true,
    pinned: false,
    requiredKpis: ['closer.lead.count'],
    canFilter: true,
  },
  {
    id: 'closerSetCount',
    label: 'Clsr Sets',
    description: 'The number of appointments set by closer',
    width: 60,
    visibleByDefault: false,
    pinned: false,
    requiredKpis: ['closer.set.count'],
    canFilter: true,
  },
  {
    id: 'closerSitCount',
    label: 'Clsr Sits',
    description: 'The number of sits by closer',
    width: 60,
    visibleByDefault: false,
    pinned: false,
    requiredKpis: ['closer.sat.count'],
    canFilter: true,
  },
  {
    id: 'closerClosedCount',
    label: 'Clsr Closes',
    description: 'The number of closes by closer',
    width: 60,
    visibleByDefault: false,
    pinned: false,
    requiredKpis: ['closer.closed.count'],
    canFilter: true,
  },
  {
    id: 'closerSitToCloseRatio',
    label: 'Clsr S/C',
    description: 'The ratio of sits to closes',
    width: 60,
    visibleByDefault: false,
    pinned: false,
    requiredKpis: ['closer.sat.count', 'closer.closed.count'],
    canFilter: true,
  },
  {
    id: 'closerApprovedCount',
    label: 'Clsr Approved',
    description: 'The number of approved accounts by closer',
    width: 60,
    visibleByDefault: false,
    pinned: false,
    requiredKpis: ['closer.approved.count'],
    canFilter: true,
  },
  {
    id: 'closerInstalledCount',
    label: 'Clsr Installs',
    description: 'The number of installed accounts by closer',
    width: 60,
    visibleByDefault: false,
    pinned: false,
    requiredKpis: ['closer.installed.count'],
    canFilter: true,
  },
  {
    id: 'closerArchivedCount',
    label: 'Clsr Archived',
    description: 'The number of archived accounts',
    width: 60,
    visibleByDefault: false,
    pinned: false,
    requiredKpis: ['closer.archived.count'],
    canFilter: true,
  },
  {
    id: 'closerArchiveToClosedRatio',
    label: 'Cncll %',
    description: 'The ratio of archived to closed accounts',
    width: 60,
    visibleByDefault: false,
    pinned: false,
    requiredKpis: ['closer.closed.count', 'closer.archived.count'],
    canFilter: true,
  },
  {
    id: 'pitchToLeadRatio',
    label: 'P/L',
    description: 'The ratio of pitches to leads',
    width: 60,
    visibleByDefault: true,
    pinned: false,
    requiredKpis: ['contact.pitches', 'setter.lead.count'],
    canFilter: true,
  },
  {
    id: 'pitchToSetRatio',
    label: 'P/S',
    description: 'The ratio of pitches to sets',
    width: 60,
    visibleByDefault: false,
    pinned: false,
    requiredKpis: ['contact.pitches', 'setter.set.count'],
    canFilter: true,
  },
  {
    id: 'daysSincePitch',
    label: 'Days Since Pitch',
    description: 'The most recent date on which a pitch was made',
    width: 140,
    visibleByDefault: false,
    pinned: false,
    requiredKpis: ['contact.lastPitchDate'],
    canFilter: false,
  },
  {
    id: 'firstPitchOfDay',
    label: 'First Pitch',
    description: 'The average time of the first pitch of the day',
    width: 100,
    visibleByDefault: false,
    pinned: false,
    requiredKpis: ['contact.firstPitchOfDay'],
    canFilter: false,
  },
  {
    id: 'lastPitchOfDay',
    label: 'Last Pitch',
    description: 'The average time of the last pitch of the day',
    width: 100,
    visibleByDefault: false,
    pinned: false,
    requiredKpis: ['contact.lastPitchOfDay'],
    canFilter: false,
  },
  {
    id: 'daysWorked',
    label: 'Days Worked',
    description: 'The number of days worked',
    width: 100,
    visibleByDefault: false,
    pinned: false,
    requiredKpis: ['contact.daysWorked'],
    canFilter: false,
  },
  {
    id: 'eventSitsCount',
    label: 'Sits',
    description: 'The number of sits',
    width: 60,
    visibleByDefault: false,
    pinned: false,
    requiredKpis: ['event.sits'],
    canFilter: true,
  },
  {
    id: 'closePercent',
    label: 'Close %',
    description: 'The percentage of sits that are closed',
    width: 70,
    visibleByDefault: false,
    pinned: false,
    requiredKpis: ['event.sits', 'event.closes'],
    canFilter: true,
  },
  {
    id: 'eventReschedulesCount',
    label: 'Resched',
    description: 'The number of appointments rescheduled',
    width: 62,
    visibleByDefault: false,
    pinned: false,
    requiredKpis: ['event.reschedules'],
    canFilter: true,
  },
  {
    id: 'daysSinceLead',
    label: 'Days Since Lead',
    description: 'The number of days since the last lead',
    width: 140,
    visibleByDefault: false,
    pinned: false,
    requiredKpis: ['account.lastLeadDate'],
    canFilter: false,
  },
  {
    id: 'daysSinceClose',
    label: 'Days Since Close',
    description: 'The number of days since the last close',
    width: 140,
    visibleByDefault: false,
    pinned: false,
    requiredKpis: ['account.lastCloseDate'],
    canFilter: false,
  },
  {
    id: 'pitchToApprovedRatio',
    label: 'P/A',
    description: 'The ratio of pitches to approved accounts',
    width: 60,
    visibleByDefault: false,
    pinned: false,
    requiredKpis: ['contact.pitches', 'setter.approved.count'],
    canFilter: true,
  },
  {
    id: 'installToCloseRatio',
    label: 'I/C %',
    description: 'The percentage of installed accounts that are closed',
    width: 60,
    visibleByDefault: false,
    pinned: false,
    requiredKpis: ['setter.installed.count', 'setter.closed.count'],
    canFilter: true,
  },
  {
    id: 'daysSinceInstall',
    label: 'Days Since Install',
    description: 'The number of days since the last install',
    width: 140,
    visibleByDefault: false,
    pinned: false,
    requiredKpis: ['account.lastInstallDate'],
    canFilter: false,
  },
  {
    id: 'overdueTasks',
    label: 'Overdue Tasks',
    description: 'The number of tasks that have been open for at least 3 days',
    width: 140,
    visibleByDefault: false,
    pinned: false,
    requiredKpis: ['task.overdue'],
    canFilter: true,
  },
  {
    id: 'meetingAttendance',
    label: 'Mtgs Attend',
    description: 'The number of meetings attended',
    width: 100,
    visibleByDefault: false,
    pinned: false,
    requiredKpis: ['meetingAttendance.count'],
    canFilter: true,
  },
  {
    id: 'evaluationCount',
    label: 'Evals',
    description: 'The number of evaluations completed by rep',
    width: 55,
    visibleByDefault: false,
    pinned: false,
    requiredKpis: ['evaluation.count'],
    canFilter: true,
  },
  {
    id: 'evaluationScore',
    label: 'Eval Score',
    description: 'The average score of completed evaluations by reps',
    width: 70,
    visibleByDefault: false,
    pinned: false,
    requiredKpis: ['evaluation.averageScore'],
    canFilter: true,
  },
  {
    id: 'evaluatedCount',
    label: 'Evald',
    description: 'The number of evaluations scored by evaluator',
    width: 55,
    visibleByDefault: false,
    pinned: false,
    requiredKpis: ['evaluation.evaluatedCount'],
    canFilter: true,
  },
  {
    id: 'evaluatedScore',
    label: 'Evald Score',
    description: 'The average score given by evaluator',
    width: 70,
    visibleByDefault: false,
    pinned: false,
    requiredKpis: ['evaluation.evaluatedAverageScore'],
    canFilter: true,
  },
  {
    id: 'lastInterviewDate',
    label: 'Last 1:1',
    description: 'Latest 1:1 occurrence date',
    width: 90,
    visibleByDefault: false,
    pinned: false,
    requiredKpis: ['event.lastInterviewDate'],
    canFilter: true,
  },
  {
    id: 'nextInterviewDate',
    label: 'Next 1:1',
    description: 'The upcoming 1:1 date',
    width: 90,
    visibleByDefault: false,
    pinned: false,
    requiredKpis: ['event.nextInterviewDate'],
    canFilter: true,
  },
  {
    id: 'messageCount',
    label: 'Msgs Sent',
    description: 'Total number of messages sent',
    width: 90,
    visibleByDefault: false,
    pinned: false,
    requiredKpis: ['message.count'],
    canFilter: true,
  },
  {
    id: 'daysSinceFirstAccess',
    label: 'Days Since First Access',
    description: 'Days since the user first logged in',
    width: 160,
    visibleByDefault: false,
    pinned: false,
    requiredKpis: ['user.firstAccessDate'],
    canFilter: false,
  },
  {
    id: 'daysSinceLastAccess',
    label: 'Days Since Last Access',
    description: 'Days since the user last logged in',
    width: 160,
    visibleByDefault: false,
    pinned: false,
    requiredKpis: ['user.lastAccessDate'],
    canFilter: false,
  },
  {
    id: 'userCount',
    label: 'Users',
    description: 'The number of users in a team',
    width: 60,
    visibleByDefault: false,
    pinned: false,
    requiredKpis: ['user.count'],
    canFilter: false,
  },
  {
    id: 'teamManagers',
    label: 'Managers',
    description: 'The managers in a team',
    width: 160,
    visibleByDefault: false,
    pinned: false,
    requiredKpis: ['team.managers'],
    canFilter: false,
  },
]

export const toKpisFilter = (columns: ColumnDef[]): ReportKpiType[] => {
  const kpiSet = new Set<ReportKpiType>()

  columns.forEach((col) => {
    col.requiredKpis.forEach((kpi) => kpiSet.add(kpi))
  })

  return Array.from(kpiSet)
}

export const getRowName = (row: ReportKpiRow): string => {
  if (row.type === 'team') return row.team.name
  if (row.type === 'user') return getName(row.user)
  if (row.type === 'time') return DateTime.fromMillis(row.range.startDate).toLocaleString()
  return '???'
}
