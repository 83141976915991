import { getRowName } from './columns'
import { type ClientKpiRow, type Avatar, type ColumnId } from './types'
import { type ReportKpiRow, getInitials } from '@terros/common'

export const transformKpiRow = (row: ReportKpiRow): ClientKpiRow => {
  const { event, contact, account, task, user, setter, closer } = row.data
  const closePercent = calculatePercentage(event?.closes, event?.sits)
  const pitchToLeadRatio = calculateRatio(contact?.pitches, setter?.lead?.count)
  const pitchToSetRatio = calculateRatio(contact?.pitches, setter?.set?.count)
  const installToCloseRatio = calculatePercentage(setter?.installed?.count, setter?.closed?.count)
  const pitchToApprovedRatio = calculateRatio(contact?.pitches, setter?.approved?.count)
  const setterSetToSitRatio = calculateRatio(setter?.set?.count, setter?.sat?.count)
  const closerSitToCloseRatio = calculateRatio(closer?.sat?.count, closer?.closed?.count)
  const closerArchiveToClosedRatio = calculatePercentage(closer?.archived?.count, closer?.closed?.count)
  const firstPitchOfDayDisplay = convertTimeOfDay(contact?.firstPitchOfDay)
  const lastPitchOfDayDisplay = convertTimeOfDay(contact?.lastPitchOfDay)
  const daysSincePitchDisplay = daysSince(contact?.lastPitchDate)
  const daysSinceLeadDisplay = daysSince(account?.lastLeadDate)
  const daysSinceCloseDisplay = daysSince(account?.lastCloseDate)
  const daysSinceInstallDisplay = daysSince(account?.lastInstallDate)
  const daysSinceFirstAccessDisplay = daysSince(user?.firstAccessDate)
  const daysSinceLastAccessDisplay = daysSince(user?.lastAccessDate)
  const avatar = getAvatarData(row)
  return {
    ...row,
    avatar,
    name: getRowName(row),
    contactCount: optionalZero(contact?.count),
    pitchCount: optionalZero(contact?.pitches),
    eventSitsCount: optionalZero(event?.sits),
    setterLeadCount: optionalZero(setter?.lead?.count),
    setterSetCount: optionalZero(setter?.set?.count),
    setterSitCount: optionalZero(setter?.sat?.count),
    setterClosedCount: optionalZero(setter?.closed?.count),
    setterApprovedCount: optionalZero(setter?.approved?.count),
    setterInstalledCount: optionalZero(setter?.installed?.count),
    setterArchivedCount: optionalZero(setter?.archived?.count),
    setterSetToSitRatio,
    setterSetToSitRatioDisplay: formatRatio(setterSetToSitRatio),
    closerLeadCount: optionalZero(closer?.lead?.count),
    closerSetCount: optionalZero(closer?.set?.count),
    closerSitCount: optionalZero(closer?.sat?.count),
    closerClosedCount: optionalZero(closer?.closed?.count),
    closerApprovedCount: optionalZero(closer?.approved?.count),
    closerInstalledCount: optionalZero(closer?.installed?.count),
    closerArchivedCount: optionalZero(closer?.archived?.count),
    closerSitToCloseRatio,
    closerSitToCloseRatioDisplay: formatRatio(closerSitToCloseRatio),
    closerArchiveToClosedRatio,
    closerArchiveToClosedRatioDisplay: formatPercent(closerArchiveToClosedRatio),
    closePercent,
    closePercentDisplay: formatPercent(closePercent),
    pitchToLeadRatio,
    pitchToLeadRatioDisplay: formatRatio(pitchToLeadRatio),
    pitchToSetRatio,
    pitchToSetRatioDisplay: formatRatio(pitchToSetRatio),
    eventReschedulesCount: optionalZero(event?.reschedules),
    installToCloseRatio,
    installToCloseRatioDisplay: formatPercent(installToCloseRatio),
    pitchToApprovedRatio,
    pitchToApprovedRatioDisplay: formatRatio(pitchToApprovedRatio),
    firstPitchOfDay: optionalZero(contact?.firstPitchOfDay),
    firstPitchOfDayDisplay,
    lastPitchOfDay: optionalZero(contact?.lastPitchOfDay),
    lastPitchOfDayDisplay,
    daysSincePitch: optionalZero(contact?.lastPitchDate),
    daysSinceLead: optionalZero(account?.lastLeadDate),
    daysSinceClose: optionalZero(account?.lastCloseDate),
    daysSinceInstall: optionalZero(account?.lastInstallDate),
    daysSinceFirstAccess: optionalZero(user?.firstAccessDate),
    daysSinceLastAccess: optionalZero(user?.lastAccessDate),
    daysSincePitchDisplay,
    daysSinceLeadDisplay,
    daysSinceCloseDisplay,
    daysSinceInstallDisplay,
    daysSinceFirstAccessDisplay,
    daysSinceLastAccessDisplay,
    daysWorked: optionalZero(contact?.daysWorked),
    overdueTasks: optionalZero(task?.overdue),
    meetingAttendance: optionalZero(row.data.meetingAttendance?.count),
    evaluationCount: optionalZero(row.data.evaluation?.count),
    evaluationScore: optionalZero(row.data.evaluation?.averageScore),
    evaluationScoreDisplay: row.data.evaluation?.averageScore ?? 'N/A',
    evaluatedCount: optionalZero(row.data.evaluation?.evaluatedCount),
    evaluatedScore: optionalZero(row.data.evaluation?.evaluatedAverageScore),
    evaluatedScoreDisplay: row.data.evaluation?.evaluatedAverageScore ?? 'N/A',
    lastInterviewDate: optionalZero(row.data.event?.lastInterviewDate),
    lastInterviewDateDisplay: getDate(row.data.event?.lastInterviewDate),
    nextInterviewDate: optionalZero(row.data.event?.nextInterviewDate),
    nextInterviewDateDisplay: getDate(row.data.event?.nextInterviewDate),
    messageCount: optionalZero(row.data.message?.count),
    quarterlyTargetInstalls: Math.round(optionalZero(row.data.plan?.quarterlyTargetInstalls)),
    quarterlyTargetLeads: Math.round(optionalZero(row.data.plan?.quarterlyTargetLeads)),
    quarterlyTargetCloses: Math.round(optionalZero(row.data.plan?.quarterlyTargetCloses)),
    quarterlyTargetPitches: Math.round(optionalZero(row.data.plan?.quarterlyTargetPitches)),
    weeklyTargetPitches: Math.round(optionalZero(row.data.plan?.weeklyTargetPitches)),
    teamManagers: row.data.team?.managers ?? 'N/A',
    userCount: optionalZero(user?.count),
  }
}

export const getDisplayedRowValue = (row: ClientKpiRow, columnId: ColumnId): string | number => {
  if (columnId === 'avatar') return "shouldn't be here"
  if (columnId === 'pitchToLeadRatio') return row.pitchToLeadRatioDisplay
  if (columnId === 'pitchToSetRatio') return row.pitchToSetRatioDisplay
  if (columnId === 'setterSetToSitRatio') return row.setterSetToSitRatioDisplay
  if (columnId === 'closerSitToCloseRatio') return row.closerSitToCloseRatioDisplay
  if (columnId === 'closerArchiveToClosedRatio') return row.closerArchiveToClosedRatioDisplay
  if (columnId === 'closePercent') return row.closePercentDisplay
  if (columnId === 'pitchToApprovedRatio') return row.pitchToApprovedRatioDisplay
  if (columnId === 'installToCloseRatio') return row.installToCloseRatioDisplay
  if (columnId === 'firstPitchOfDay') return row.firstPitchOfDayDisplay
  if (columnId === 'lastPitchOfDay') return row.lastPitchOfDayDisplay
  if (columnId === 'daysSincePitch') return row.daysSincePitchDisplay
  if (columnId === 'daysSinceLead') return row.daysSinceLeadDisplay
  if (columnId === 'daysSinceInstall') return row.daysSinceInstallDisplay
  if (columnId === 'daysSinceClose') return row.daysSinceCloseDisplay
  if (columnId === 'evaluationScore') return row.evaluationScoreDisplay
  if (columnId === 'evaluatedScore') return row.evaluatedScoreDisplay
  if (columnId === 'lastInterviewDate') return row.lastInterviewDateDisplay
  if (columnId === 'nextInterviewDate') return row.nextInterviewDateDisplay
  if (columnId === 'daysSinceFirstAccess') return row.daysSinceFirstAccessDisplay
  if (columnId === 'daysSinceLastAccess') return row.daysSinceLastAccessDisplay
  if (columnId === 'teamManagers') return row.teamManagers
  return row[columnId]
}

export const getDate = (date?: number): string => {
  if (!date) return '--'
  const today = new Date(date)
  return today.toLocaleDateString()
}

const daysSince = (date?: number): string => {
  if (!date) return '--'
  const today = new Date()
  const lastDate = new Date(date)
  const diffTime = Math.abs(today.getTime() - lastDate.getTime())
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  return diffDays.toString()
}

export function convertTimeOfDay(time?: number): string {
  if (!time) return '--:--'

  const hours = Math.floor(time / 3600)
  const minutes = Math.floor((time % 3600) / 60)

  const minutesString = minutes < 10 ? '0' + minutes : minutes.toString()

  const meridiem = hours < 12 ? 'AM' : 'PM'
  const adjustedHours = hours % 12 || 12

  return `${adjustedHours}:${minutesString} ${meridiem}`
}

const getAvatarData = (row: ReportKpiRow): Avatar => {
  if (row.type === 'user')
    return { avatarUrl: row.user.avatarUrl, avatarBlurhash: row.user.avatarBlurhash, initials: getInitials(row.user) }
  if (row.type === 'team')
    return {
      avatarUrl: row.team.avatarUrl,
      avatarBlurhash: row.team.avatarBlurhash,
      initials: getInitialism(row.team.name),
    }
  return { initials: '--' }
}

export const calculateRatio = (numerator: number | undefined, denominator: number | undefined): number => {
  return numerator && denominator && denominator > 0 ? numerator / denominator : 0
}

export const calculatePercentage = (numerator: number | undefined, denominator: number | undefined): number => {
  return numerator && denominator && denominator > 0 ? (numerator / denominator) * 100 : 0
}

export const formatPercent = (num: number): string => num.toFixed(0)
export const formatRatio = (num: number): string => (num ? Math.round(num) + '/1' : '0')
export const optionalZero = (num?: number): number => num ?? 0

export const getInitialism = (name: string): string => {
  const split = name.split(' ')
  if (split.length > 1) {
    return ''.concat(split[0].substring(0, 1), split[split.length - 1].substring(0, 1))
  }
  return name.substring(0, 2)
}
